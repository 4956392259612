/* eslint-disable no-underscore-dangle */

import React, { JSX } from "react";
import { render } from "react-dom";
import I18nProvider from "../../lib/I18nProvider";

const getGlobalInitialDataObject = () =>
  "__INITIAL__DATA__" in window && typeof window.__INITIAL__DATA__ === "object"
    ? (window.__INITIAL__DATA__ as Record<string, unknown>)
    : {};

export const initializeClientSubApplication = (
  applicationShellIdPrefix: string,
  ApplicationComponent: (props: {
    data: Record<string, unknown>;
  }) => JSX.Element
) => {
  Object.entries(getGlobalInitialDataObject()).forEach(([id, dataPayload]) => {
    if (typeof dataPayload !== "object" || dataPayload === null) {
      return;
    }

    const domElement = document.getElementById(
      `${applicationShellIdPrefix}${id}`
    );

    if (domElement === null) {
      return;
    }

    let liftedTranslations: object;
    let liftedInitialData: Record<string, unknown>;

    if (
      !("translations" in dataPayload) ||
      typeof dataPayload.translations !== "object" ||
      dataPayload.translations === null
    ) {
      const { ...initialData } = dataPayload;
      liftedTranslations = {};
      liftedInitialData = initialData;
    } else {
      const { translations, ...initialData } = dataPayload;
      liftedTranslations = translations;
      liftedInitialData = initialData;
    }

    const SubApplication = () => (
      <I18nProvider translations={liftedTranslations}>
        <ApplicationComponent data={liftedInitialData} />
      </I18nProvider>
    );

    render(<SubApplication />, domElement);
  });
};
